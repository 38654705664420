@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: defaultFont;
  src: url('../src/assets/fonts/Champagne.ttf');
}

@font-face {
  font-family: boldFont;
  src: url('../src/assets/fonts/ChampagneBold.ttf');
}

* {
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 22px
}

body {
  margin: 0;
  font-family: defaultFont;
  background-color: #222222;
}

.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #CB9831;
  z-index: 999;
}

.btn {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: #CB9831;
  background: none;
  cursor: pointer;
  /* padding: 25px 80px; */
  display: inline-block;
  /* margin: 15px 30px; */
  text-transform: uppercase;
  /* letter-spacing: 1px; */
  /* font-weight: 700; */
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-1 {
  border: 2px solid #CB9831;
  /* color: #fff; */
}

.btn:before {
  /* font-family: 'icomoon'; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.btn:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-1e {
  overflow: hidden;
}

.btn-1e:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background: #CB9831;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

@media (prefers-color-scheme: dark) {
  .btn-1e:after {
    background: #CB9831;
  }
}

.btn-1e:hover,
.btn-1e:active {
  color: white;
}

.btn-1e:hover:after {
  height: 260%;
  opacity: 1;
}

.btn-1e:active:after {
  height: 400%;
  opacity: 1;
}

.btn-reverse {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: white;
  background: none;
  cursor: pointer;
  /* padding: 25px 80px; */
  display: inline-block;
  /* margin: 15px 30px; */
  text-transform: uppercase;
  /* letter-spacing: 1px; */
  /* font-weight: 700; */
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-reverse-1 {
  border: 2px solid #CB9831;
  /* color: #fff; */
}

.btn-reverse:before {
  /* font-family: 'icomoon'; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.btn-reverse:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-reverse-1e {
  overflow: hidden;
}

.btn-reverse-1e:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background: #CB9831;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);

}

.btn-reverse-1e:hover,
.btn-reverse-1e:active {
  color: white;
}

.btn-reverse-1e:hover:after {
  height: 260%;
  opacity: 1;
}

.btn-reverse-1e:active:after {
  height: 400%;
  opacity: 1;
}


.font-bold {
  font-family: boldFont;
}

.landing {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../src/assets/images/landing.jpg');
  background-size: cover;
}

.projects {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../src/assets/images/projects-landing.jpg');
  background-size: cover;
}

.about {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../src/assets/images/about-bg.jpg');
  background-size: cover;
}

.projects-template {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../src/assets/images/projects-template.svg');
  background-size: cover;
}

.project:hover {
  background-size: 115%;
}

.project-1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../src/assets/images/project-img1.jpg');
  background-size: 100%;
  background-position: center;

}

.project-2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../src/assets/images/project-img2.jpg');
  /* background-size: cover; */
  background-size: 100%;
  background-position: center;
}

.project-3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../src/assets/images/construction.jpg');
  /* background-size: cover; */
  background-size: 100%;
  background-position: center;
}

.project-4 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../src/assets/images/project-img4.jpg');
  /* background-size: cover; */
  background-size: 100%;
  background-position: center;
}

svg:hover {
  fill: red;
}

.swiper-pagination-bullet {
  background-color: #888888 !important;
}

.swiper-pagination-bullet-active {
  background-color: #CB9831 !important;
}